.leaflet-container {
    height: 805px;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
}
#map {
    height: 100%;
}
.content-page {
    padding: 0;
}